import React, { useEffect, useLayoutEffect, useState } from "react";
import { Form, Card, Button, Alert } from "react-bootstrap";
import validator from "validator";
import { FormTextField } from "./FormTextField";
import { FormField } from "./FormField";
import { FormCheck } from "./FormCheck";
import Select, { ActionMeta } from 'react-select'
import { States, State } from "./States";
import { FormCheckRadio } from "./FormCheckRadio";
import { UsaStates } from 'usa-states';
import { FormService } from "./FormService";
import { FormGroup } from "reactstrap";

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, lastStep, mainHandleFormFieldChange, values }: {
  nextStep: Function, lastStep: Function, mainHandleFormFieldChange: Function, values: any
}) => {

  type Property = {
    key: string,
    value: string
  }

  type State = {
    label: string,
    value: string
  }

  type Item = {
    name: string,
    label: string
  }

  const yesNoForRadioItems:Item[] = [
    { name: "DoYouHaveDesignatedDesk", label: "Do you have a designated desk?" },
    { name: "DoYouHaveAnOfficeChair", label: "Do you have an office chair?" },
    { name: "DoYouHaveSeparateMonitors", label: "Do you have separate monitors?" },
    //{ name: "DoYouHavePetsThatFreelyMove", label: "Do you have pets that freely move around your location (.ie. dogs, cats)?" },
    { name: "ExperiencingAnyStrainInWristsOrFingers", label: "Have you or are you experiencing any strain in your wrists or fingers?" }];

  const yesNoAnythingChangedRadioItems:Item[] = [
    //{ name: "HasYourWorkspaceChangedSinceYouLastUploadedThisPicture", label: "Has your workspace changed since you last uploaded this picture?" }
  ];
  const selectStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
      ...provided,
      //borderBottom: '2px dotted green',
      //color: state.isSelected ? 'yellow' : 'black',
      backgroundColor: state.isSelected ? '#3cb333' : 'white'
    }),
  };

  const enablePicture = false;
  const nothingChangedEnabled = false;
  //creating error state for validation
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [lastFormState, setLastFormState] = useState<State | null>(null);
  const [pictureChanged, setPictureChanged] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [lastFormContent, setLastFormContent] = useState<string | null>();

  const [sendingLastFormRequestState, setSendingLastFormRequestState] = useState("Checking previous form ...");
  const [lastFormRequestDone, setLastFormRequestDone] = useState<boolean | null>(null);
  const [lastFormExists, setLastFormExists] = useState<boolean>(false);
  const [lastFormProperties, setLastFormProperties] = useState<Property[]>([]);
  const [lastFormPictureUrl, setLastFormPictureUrl] = useState();
  const [lastFormDate, setLastFormDate] = useState<Date>();

  const [submitType, setSubmitType] = useState<'continue' | 'nothingChanged' | undefined>(undefined);

  var usStates = new UsaStates();
  const states = usStates.states.map((state) => ({ label: state.name as string, value: state.name as string}));

  const handleFormFieldChange = (fieldName: string, value: any) => {
    setFormChanged(true);
    mainHandleFormFieldChange(fieldName, value);
  };

  useLayoutEffect(() => {
    console.log("!!!!!!!!!!  useEffect");
    
      setLastFormRequestDone(true);
      loadLastForm();
  }, []);

  const loadLastForm = () => {
    console.log("!!!!!!!!!!  loadLastForm");
    const formService = new FormService();
    formService.getLastForm()
      .then(resp => {
        setSendingLastFormRequestState("Completed: " + resp);
        console.log("!!!!!!!!!!  FormService Response   ");
        console.log(resp);

        if (resp != null && resp.success && resp.result != null && resp.result != undefined) {
          console.log(resp.result);
          const formId = resp.result.id;

          //setLastFormContent(resp.result.formContent);
          setLastFormDate(new Date(resp.result.timestamp));
          processLastFormContent(resp.result.formContent);

          mainHandleFormFieldChange("LastFormId", formId);
          formService.getFormPictureUrl(formId)
            .then(resp => {
              console.log(resp);
              setLastFormPictureUrl(resp.result);
            })
            .catch(error => {
              console.log(error);
              throw (error);
            });
        }
        else {
          //setLastFormExists(true);
        }
      })
      .catch(error => {
        setSendingLastFormRequestState("Error: " + error);
        throw (error);
      });
    }

  const processLastFormContent = (lastFormContent: string) => {
    
    if (lastFormContent != null && lastFormContent != undefined) {
      
      console.log("!!!!!!!!!! processLastFormContent");
      console.log(JSON.parse(lastFormContent));

      const parsedLastFormContent = JSON.parse(lastFormContent);
      const lastFormItems: Property[] = [];
      for (const [key, value] of Object.entries(parsedLastFormContent)) {
        console.log(key + ": " + value);
        if (key != "HasYourWorkspaceChangedSinceYouLastUploadedThisPicture")
          lastFormItems.push({ key: key as string, value: value as string })
      }
      setLastFormProperties(lastFormProperties => [...lastFormProperties, ...lastFormItems]);
      setLastFormExists(true);
      //console.log(parsedLastFormContent.Name);
      mainHandleFormFieldChange("Name", parsedLastFormContent.Name);
      setName(parsedLastFormContent.Name);
      
      var lastSelectedState = states.find((state: { value: string; }) => state.value == parsedLastFormContent.State);

      setLastFormState(lastSelectedState == undefined ? null : lastSelectedState);
      mainHandleFormFieldChange("State", parsedLastFormContent.State);

      yesNoForRadioItems.map(item => {
        var lastItemValue = lastFormItems.find(lastItem => lastItem.key == item.name);
        if ((lastItemValue == null || lastItemValue == undefined) == false) {
          console.log("Found in last form: " + item.name + " = " + lastItemValue?.value);
          mainHandleFormFieldChange(item.name, lastItemValue?.value);
        }
      });

      yesNoAnythingChangedRadioItems.map(item => {
        mainHandleFormFieldChange(item.name, "No");
      });
    }
  }

  /*if (name === "") {
    const formService = new FormService();
    formService.getUserName()
      .then(resp => {
        console.log(resp);
        setName(resp.name);
        values.Name = resp.name;
      })
      .catch(error => {
        console.log(error);
        throw (error);
      });
  }*/

  // after form submit validating the form data using validator
  const submitFormData = (e: { preventDefault: () => void; }, submitType: 'continue' | 'nothingChanged' | undefined) => {
    e.preventDefault();
    console.log("Form before next step");
    console.log(values);
    console.log("Submit type");
    console.log(submitType);
    // checking if value of first name and last name is empty show error else take to step 2
    if (
      validator.isEmpty(values.Name)
    ) {
      setError(true);
      setShowError(true);
      setErrorMessage("Name is empty");
      console.log(showError);
      console.log("Name is empty "); //see formik for future
    }
    else if (
      values.State === undefined
    ) {
      setError(true);
      setShowError(true);
      setErrorMessage("State is empty");
      console.log(showError);
      console.log("State is not selected "); //see formik for future
    }
    else if (
      values.DoYouHaveDesignatedDesk === undefined
    ) {
      setError(true);
      setShowError(true);
      setErrorMessage("Not answered: Do you have a designated desk?");
      console.log(showError);
      console.log("Not answered: Do you have a designated desk?"); //see formik for future
    }
    else if (
      values.DoYouHaveAnOfficeChair === undefined
    ) {
      setError(true);
      setShowError(true);
      setErrorMessage("Not answered: Do you have an office chair?");
      console.log(showError);
      console.log("Not answered: Do you have an office chair?"); //see formik for future
    }
    else if (
      values.DoYouHaveSeparateMonitors === undefined
    ) {
      setError(true);
      setShowError(true);
      setErrorMessage("Not answered: Do you have separate monitors?");
      console.log(showError);
      console.log("Not answered: Do you have separate monitors?"); //see formik for future
    }
    /*else if (
      values.DoYouHavePetsThatFreelyMove === undefined
    ) {
      setError(true);
      setShowError(true);
      setErrorMessage("Not answered: Do you have pets that freely move around your location (.ie. dogs, cats)?");
      console.log(showError);
      console.log("Not answered: Do you have pets that freely move around your location (.ie. dogs, cats)?"); //see formik for future
    }*/
    else if (
      values.ExperiencingAnyStrainInWristsOrFingers === undefined
    ) {
      setError(true);
      setShowError(true);
      setErrorMessage("Not answered: Have you or are you experiencing any strain in your wrists or fingers?");
      console.log(showError);
      console.log("Not answered: Have you or are you experiencing any strain in your wrists or fingers?"); //see formik for future
    }
    else {
      setError(false);
      setShowError(false);
      setErrorMessage("");
      console.log("No error found");
      setSubmitType(submitType);
    }
  };

  useEffect(() => {
    console.log(submitType);
    console.log(values.HasYourWorkspaceChangedSinceYouLastUploadedThisPicture);
    if (!error) {

      if (submitType == 'continue') {
        mainHandleFormFieldChange("NothingChanged", "No");
        if (values.HasYourWorkspaceChangedSinceYouLastUploadedThisPicture === undefined
          || values.HasYourWorkspaceChangedSinceYouLastUploadedThisPicture == "Yes") {
          console.log("Navigate next step");
          nextStep();
        }
        else {
          console.log("Navigate last step");
          lastStep();
        }
      }
      else if (submitType == 'nothingChanged') {

        mainHandleFormFieldChange("NothingChanged", "Yes");
        lastStep();
      }
    }
  }, [submitType]);

  const validateField = (value: string) => {
    console.log("Validating:" + value)
    if (
      validator.isEmpty(value)
    ) {
      setError(true);
      console.log("Validaton failed");
      return (
        <Form.Text style={{ color: "red" }}>
          This is a required field
        </Form.Text>
      );
    }
    console.log("Validaton success")
    return "";
  }

  const onChangeState = (option: State | null, actionMeta: ActionMeta<State>) => {
    console.log("State changed ");
    console.log(option);
    setLastFormState(option);
    handleFormFieldChange("State", option?.value);
  }



  /*if (!lastFormRequestDone)
    return (
      <div>{sendingLastFormRequestState}</div>
    )
  else*/
  return (
    <div>
      <Card style={{}}>
        <Card.Body>
          {
            lastFormExists ?
              <>
                <h2>Has anything changed?</h2>
                <span>Information entered on {lastFormDate?.toLocaleDateString()} {lastFormDate?.toLocaleTimeString()}</span>
              </>
              : null
          }
          <Form>
            <FormField
              handleFormData={handleFormFieldChange}
              fieldName="Name"
              displayName="Name"
              value={name}
              type="text"
              validate={validateField}></FormField>

            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Select
                options={states}
                onChange={onChangeState}
                value={lastFormState}
                styles={selectStyles}></Select>
            </Form.Group>

            <>{
              lastFormExists ?
              <>
              {yesNoForRadioItems.map((yesNoItem) =>
                <Form.Group className="mb-3" key={`formgroup-fromlast-${yesNoItem.name}`}>
                  <Form.Label>{yesNoItem.label}
                  </Form.Label>                  
                  
                  <FormCheckRadio
                    handleFormData={handleFormFieldChange}
                    fieldName={yesNoItem.name}
                    validate={validateField}
                    currentValue={lastFormProperties.findIndex((property) => { return property.key == yesNoItem.name && property.value == "Yes" }) >= 0 }                      
                  ></FormCheckRadio>
                </Form.Group>)}
                </>
                :
                <>
              {yesNoForRadioItems.map((yesNoItem) =>
                <Form.Group className="mb-3" key={`formgroup-new-${yesNoItem.name}`}>
                  <Form.Label>{yesNoItem.label}
                  </Form.Label>                  
                  
                  <FormCheckRadio
                    handleFormData={handleFormFieldChange}
                    fieldName={yesNoItem.name}
                    validate={validateField}
                    currentValue={null}                      
                  ></FormCheckRadio>
                </Form.Group>)}
                </>
}</>


            {
              lastFormExists ?
                <>
                  {yesNoAnythingChangedRadioItems.map((yesNoItem) =>
                    <Form.Group className="mb-3" key={`formgroup-lastform-${yesNoItem.name}`}>
                      <Form.Label>{yesNoItem.label}
                      </Form.Label>

                      <FormCheckRadio
                        handleFormData={(input: any, radioValue: any) => {
                          setPictureChanged(radioValue === "Yes");
                          handleFormFieldChange(input, radioValue);
                        }}
                        fieldName={yesNoItem.name}
                        validate={validateField}
                        currentValue={lastFormExists === true ? lastFormProperties.findIndex((property) => { return property.key == yesNoItem.name && property.value == "Yes" }) >= 0 : null}
                      ></FormCheckRadio>
                    </Form.Group>
                  )}
                  {
                    (lastFormExists && enablePicture && lastFormPictureUrl != undefined) ?
                      <FormGroup>
                        <img src={lastFormPictureUrl} alt="Loading image..." className="img-thumbnail"></img>
                      </FormGroup>
                      : null
                  }
                </>

                : null
            }


            <div className="d-grid gap-2" /*style={{ display: "flex", justifyContent: "space-around" }}*/>
              {(lastFormExists && nothingChangedEnabled) ?
                <Button variant="primary" className="mt-3" type="submit"
                  onClick={e => {
                    console.log(submitType);
                    submitFormData(e, 'nothingChanged');
                  }}>
                  Nothing has changed
                </Button>
                : null}
              <Button variant="primary" className="mt-3" type="submit" disabled={nothingChangedEnabled && !formChanged}
                onClick={e => {
                  submitFormData(e, 'continue');
                }}>
                {nothingChangedEnabled ? "Continue" : "Submit"}
              </Button>
            </div>


          </Form>
          {showError ?
            <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>
                {errorMessage}
              </p>
            </Alert> : <span></span>
          }

        </Card.Body>
      </Card>
    </div>
  );
};

export default StepOne;
