import React, { useState } from "react";
import { Form, Card, Button, Row, Col } from "react-bootstrap";
import validator from "validator";
import Webcam from "react-webcam";
import { ErrorBoundary } from "./ErrorBoundary";

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = ({ nextStep, prevStep, mainHandleFormFieldChange, values }) => {

  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";

//creating error state for validation
  const [error, setError] = useState(false);
  const [picture, setPicture] = useState();
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);



  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: facingMode
  };

  const handleSwitchCameraClick = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);


  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (
      validator.isEmpty(values.Name)
    ) {
      setError(true);
    } else {
      nextStep();
    }
  };

  const WebcamCapture = () => (
    <Webcam
      audio={false}
      height={240}
      width={352}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "fill"
     }}
      screenshotFormat="image/jpeg"
      forceScreenshotSourceSize
      videoConstraints={videoConstraints}
      className="d-flex justify-content-center"
    >
      {({ getScreenshot }) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button variant="primary" className="mt-3" onClick={(event) => {
            event.preventDefault();
            const imageSrc = getScreenshot();
            console.log(imageSrc);
            setPicture(imageSrc);
            mainHandleFormFieldChange("picture", imageSrc);
          }}>
          Capture photo
        </Button>
        <Button variant="primary" className="mt-3" onClick={handleSwitchCameraClick}>
          Switch camera
        </Button>
        </div>
        /*<Row className="justify-content-md-center mb-3">
          <Col sm={6} >
          <Button variant="primary" className="mt-3" onClick={(event) => {
            event.preventDefault();
            const imageSrc = getScreenshot();
            console.log(imageSrc);
            setPicture(imageSrc);
            handleFormFieldChange("picture", imageSrc);
          }}>
          Capture photo
        </Button>
        </Col>
        <Col sm={6} >
          <Button variant="primary" className="mt-3" onClick={handleSwitchCameraClick}>
          Switch camera
        </Button>
        </Col>
        </Row>*/
      )}
    </Webcam>
  );

  const previousButtonHandler = (event) => {
    event.preventDefault();
    const button = event.currentTarget;
    prevStep();
  };

  const takeAnotherPictureButtonHandler= (event) => {
    event.preventDefault();
    const button = event.currentTarget;
    setPicture(null);
  };

  const onFileChange = (event) => {
    event.preventDefault();
    // Update the state 
    const reader = new FileReader();

    reader.onloadend = function () {
      console.log(reader.result);
      setPicture(reader.result);
      mainHandleFormFieldChange("picture", reader.result);
    };

    reader.onerror = function () {
      console.log("couldn't read the file");
    };


    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Card style={{  }}>
        <Card.Body>
          <Form onSubmit={submitFormData}>
          {picture ? 
            <Form.Group className="mt-10">
              <Row className="justify-content-md-center">
                <Button onClick={takeAnotherPictureButtonHandler}>Take another picture</Button>
              </Row>
            </Form.Group> :          
            <>
              <Form.Group as={Row} className="mb-3">
                <Form.Control type="file" onChange={onFileChange} accept="image/jpeg"/>
              </Form.Group>
              
              <Form.Group  as={Row} className="mb-3">
                  <Form.Label className="text-center" style={{ width: "100%" }}>--- OR ---</Form.Label>
              </Form.Group>

              <Form.Group  as={Row}  className="justify-content-md-center mb-3">
                <Col sm={6} >
                  <Form.Label className="text-center" style={{ width: "100%" }}>Take a picture</Form.Label>
                </Col>
              </Form.Group>

              <Row className="align-items-center">
                <Form.Group className="mb-3">
                      <ErrorBoundary>
                        <WebcamCapture />
                      </ErrorBoundary>
                </Form.Group>
              </Row>
            </>
            }
            <Form.Group as={Row} className="mb-3">
              {picture ? <img src={picture} className='img-thumbnail' /> : null}
            </Form.Group>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button variant="primary" onClick={previousButtonHandler}>
                Previous
              </Button>

              <Button variant="primary" onClick={submitFormData}>
                Continue
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default StepTwo;
