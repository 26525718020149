import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import Main from './components/dialog/Main';
import { FetchData } from './components/FetchData';


import './custom.css'
import FormsList from './components/forms/forms-list';
import FormView from './components/forms/form-view';


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <AuthorizeRoute path='/' component={Main} step="1" exact />
        <AuthorizeRoute path='/new' component={Main} step="1" exact />
        <AuthorizeRoute path='/forms-list' component={FormsList} />
        <AuthorizeRoute path='/form-view' component={FormView} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
