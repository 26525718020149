import React, { useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";

export type FormCheckProps = {
  handleFormData: Function, 
  checkValue: string,
  fieldName: string, 
  displayName: string, 
  type: FormCheckType,
  defaultValue:boolean, 
  validate: Function}

// creating functional component ans getting props from app.js and destucturing them
export const FormCheck: React.FC<FormCheckProps> = ({ handleFormData, checkValue, fieldName, displayName, type, defaultValue,  validate }) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  const handleInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
    // input value from the form     
    const { value } = e.target;
    console.log(e);
    console.log("FormCheckField:"+value + " - " + checkValue);
    handleFormData(fieldName, checkValue);
    //setError(validate(checkValue));
    console.log("ValidationResult:"+{error});
  };

  return (
    
              <Form.Check
                inline
                defaultChecked={defaultValue}
                style={{ border: error ? "2px solid red" : "" }}
                name={fieldName}
                type={type}
                label={displayName}
                key={`${fieldName}`}
                onChange={(evt) => handleInputData(evt)}
              />);

};

