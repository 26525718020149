type FieldInfo = {
    Label: string,
    ViewVisible: boolean,
}

export class FormFieldToLabelService {

    dictionary = new Map<string, FieldInfo>();
    

    constructor()
    {
        this.dictionary.set('Name', {Label: 'Name', ViewVisible: true});
        this.dictionary.set('State', {Label: 'State', ViewVisible: true});
        this.dictionary.set('DoYouHaveDesignatedDesk', {Label: 'Do you have a designated desk?', ViewVisible: true});
        this.dictionary.set('DoYouHaveAnOfficeChair', {Label: 'Do you have an office chair?', ViewVisible: true});
        this.dictionary.set('DoYouHaveSeparateMonitors', {Label: 'Do you have separate monitors?', ViewVisible: true});
        this.dictionary.set('DoYouHavePetsThatFreelyMove', {Label: 'Do you have pets that freely move around your location (.ie. dogs, cats)?', ViewVisible: true});
        this.dictionary.set('ExperiencingAnyStrainInWristsOrFingers', {Label: 'Have you or are you experiencing any strain in your wrists or fingers?', ViewVisible: true});
    }    

    public GetLabel(fieldName: string)
    {
        return this.dictionary.get(fieldName);
    }
}