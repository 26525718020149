import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { FormService } from '../dialog/FormService';


const FormsList = () => {
    const [sendingState, setSendingState] = useState("Loading...");
    const [formsRequestSent, setFormsRequestSentState] = useState(false);
    const [dataList, setDataList] = useState<any[]>([]);

    useEffect(() => {
      if (!formsRequestSent)
      {
        setFormsRequestSentState(true);
        setSendingState("Sending request...");
  
        const formService = new FormService();
        formService.getForms()
        .then(resp => {
          setSendingState("Completed: " + resp);
          setDataList(resp.result)
          console.log(resp);
        })
        .catch(error => {
          setSendingState("Error: " + error)
          throw(error);
        });
        
      } 
    },[]);
    
    return (
      <div className="container">
            <div className="row justify-content-md-center">
                <div className="col col-md-6">
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Form filling date</th>
                </tr>
            </thead>
            <tbody>
            {
            dataList
            .sort(
              (objA, objB) => Number(Date.parse(objB.timestamp)) - Number(Date.parse(objA.timestamp)),
            )
            .map((item) => (
              <tr key={item.id}>
                <td>{new Date(item.timestamp).toLocaleString()}</td>
                <td><Link to={{
                  pathname: "/form-view",
                  state: {item:item}
                }}>
                  View
                </Link>
                </td>
              </tr>
          ))}
            </tbody>
        </Table>
        </div>
        </div>
        </div>
    );
};

export default FormsList;