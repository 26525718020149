import React, { useState } from "react";
import { ToggleButton } from "react-bootstrap";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";
import { FormCheck } from "./FormCheck";

export type FormCheckRadioProps = {
    handleFormData: Function, 
    fieldName: string, 
    validate: Function,
    currentValue: boolean | null}
  
export const FormCheckRadio: React.FC<FormCheckRadioProps> = ({ handleFormData, fieldName, validate, currentValue }) => {
  //console.log(fieldName); 
  //console.log(["Yes", "No"].map((choice, idx) => (  (currentValue == true && choice == "Yes") || (currentValue == false && choice == "No"))));
    return (<div key={`inline-${fieldName}`} className="mb-3">

                {["Yes", "No"].map((choice, idx) => (
                  <FormCheck 
                  handleFormData={handleFormData}
                  checkValue={choice}
                  fieldName={fieldName}
                  displayName={choice}
                  defaultValue={(currentValue == true && choice == "Yes") || (currentValue == false && choice == "No")}
                  type="radio"
                  validate={validate}                  
                  key={`${fieldName}-${choice}`}></FormCheck>
                  /*<ToggleButton
            key={`${fieldName}-${idx}`}
            id={`radio-${fieldName}-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-success' : 'outline-danger'}
            name="radio"
            value={choice}
            onChange={(e) => handleFormData(e)}
          >
            {choice}
          </ToggleButton>*/
                ))}              
                </div>)
}