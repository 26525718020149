import React , { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { FormService } from "./FormService";

const Final = ({ values, nothingChanged } : {values:any, nothingChanged: boolean}) => {
  const [sendingState, setSendingState] = useState("Loading...");
  const [mountedState, setMountedState] = useState(false);
  const [devState, setDevState] = useState(false);
  const [formSent, setFormSentState] = useState(false);

  useEffect(() => {
    if (!formSent)
    {
      setFormSentState(true);
      setSendingState("Sending...");

      const formService = new FormService();

      if (nothingChanged)
      {
        console.log("nothing changed = " + nothingChanged);
        formService.postNothingChanged()
        .then(resp => setSendingState("Completed: " + resp))
        .catch(error => {
          setSendingState("Error: " + error)
          throw(error);
        });
      }
      else
      {
        
        formService.postForm(values)
        .then(resp => setSendingState("Completed: " + resp))
        .catch(error => {
          setSendingState("Error: " + error)
          throw(error);
      });
      }
      
    } 
  },[]);
  
  return (
    <>
      <Card className="text-center" style={{  }}>
        <Card.Body>
        <Card.Title>
          {devState ? sendingState : (sendingState.startsWith("Completed: OK") ? "Form sent" : `Form wasn't sent: ${sendingState}`)}
          </Card.Title>
          {devState ?
          <Card.Text>
          <pre>            
            <code>
              {JSON.stringify(values)}
            </code>
          </pre>
          </Card.Text> : <></>
          }

        </Card.Body>
      </Card>
    </>
  );
};

export default Final;
