import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom'
import { FormService } from '../dialog/FormService';
import { FormFieldToLabelService } from './form-fields';


const FormView = () => {

    interface FormViewRequest {
        item: any;
        data: any
      }

    const location = useLocation();
    const state = location.state as FormViewRequest;

    const [pictureUrl, setPictureUrl] = useState(/*localStorage.getItem('pictureUrl') || undefined*/);
    const [formContent, setFormContent] =  useState(/*JSON.parse(localStorage.getItem('formContent') as string) || */JSON.parse(state.item.formContent));
   
    /*useEffect(() => {
        console.log("BP2");
        console.log(pictureUrl);
        console.log(formContent);
        localStorage.setItem('pictureUrl', pictureUrl as string);
        localStorage.setItem('formContent', JSON.stringify(JSON.parse(state.item.formContent)));
      }, [pictureUrl, formContent]);*/

    const items : Property[] = [];

    type Property = {
        key: string,
        value: string
    }
    for (const [key, value] of Object.entries(formContent)) {
        items.push({key: key as string, value: value as string})
    }
    
    const formService = new FormService();
    formService.getFormPictureUrl(state.item.id)
      .then(resp => {
        console.log(resp);
        setPictureUrl(resp.result);        
      })
      .catch(error => {
        console.log(error);
        throw (error);
      });

    const formFieldService = new FormFieldToLabelService();
    const history = useHistory();
    const enablePicture = false;

    return (
        <>
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col col-md-4">
                        {
                            enablePicture && pictureUrl != undefined ?                             
                                            <img src={pictureUrl} alt="Loading image..." className="img-thumbnail"></img>
                                            : null
                        }
                        <Form>            
                            {
                                items.map((item, index) => {
                                    const field = formFieldService.GetLabel(item.key);
                                    if (field?.ViewVisible)
                                    {
                                        return (            
                                            <Form.Group className="mb-3" key={item.key}>
                                                <Form.Label>{field.Label}</Form.Label>
                                                <Form.Control value={item.value} readOnly/>
                                            </Form.Group>
                                            );
                                    }
                                })
                            }
                        </Form>
                        <button type="button" onClick={ () => history.goBack() } className="btn btn-primary">Go Back</button>
                </div>
            </div>
        </div>
        </>
    );
};

export default FormView;