import authService from '../api-authorization/AuthorizeService'
import { v1 as uuid } from 'uuid';

export class FormService {
  public async postForm(formData: any) {
      const token = await authService.getAccessToken();
      console.log(token);
      console.log(JSON.stringify({jsonString: `${JSON.stringify(formData)}`}));

      const now = new Date();

      const response = await fetch('api/form', {
        method: 'POST',
        headers: !token ? {} : { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({trackingId: `${uuid()}`, clientUtcTimeStamp: `${now.toUTCString()}`, jsonString: `${JSON.stringify(formData)}`})
      });
      return response.statusText;
    }

    public async postNothingChanged() {
      const token = await authService.getAccessToken();
      console.log(token);

      const now = new Date();

      const response = await fetch('api/form/nothingchanged', {
        method: 'POST',
        headers: !token ? {} : { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify(
          {trackingId: `${uuid()}`, clientUtcTimeStamp: `${now.toUTCString()}`})
      });
      return response.statusText;
    }

    public async getUserName() {
      const token = await authService.getAccessToken();
      const now = new Date();
      console.log(token);
      const response = await fetch('api/user', {
        method: 'GET',
        headers: !token ? {} : { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8' } 
      });
      return await response.json();
    }

    public async getFormPictureUrl(formId: string) {
      const token = await authService.getAccessToken();
      const now = new Date();
      console.log(token);
      const response = await fetch('api/form/picture?formId=' + formId, {
        method: 'GET',
        headers: !token ? {} : { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8' } 
      });
      return await response.json();
    }

    public async getForms() {
      const token = await authService.getAccessToken();
      const now = new Date();
      console.log(token);
      const response = await fetch('api/form', {
        method: 'GET',
        headers: !token ? {} : { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8' } 
      });
      return await response.json();
    }

    public async getLastForm() {
      const token = await authService.getAccessToken();
      const now = new Date();
      console.log(token);
      const response = await fetch('api/form/last', {
        method: 'GET',
        headers: !token ? {} : { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8' } 
      });
      return await response.json();
    }
}