import React, { useState } from "react";
import { Form, Card, Button } from "react-bootstrap";

export type FormFieldProps = {
  handleFormData: Function, 
  fieldName: string, 
  displayName: string, 
  type: string,
  value:string, 
  validate: Function}

// creating functional component ans getting props from app.js and destucturing them
export const FormField: React.FC<FormFieldProps> = ({ handleFormData, fieldName, displayName, type, value, validate }) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  const handleInputData = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // input value from the form     
    const { value } = event.target;
    console.log("FormTextField:"+value);
    handleFormData(fieldName, value);
    setError(validate(value));
    console.log("ValidationResult:"+{error});
  };

  return (
    
            <Form.Group className="mb-3">
              <Form.Label>{displayName}</Form.Label>
              <Form.Control
                style={{ border: error ? "2px solid red" : "" }}
                name={fieldName}
                defaultValue={value}
                type={type}
                placeholder={displayName}
                onChange={(evt) => handleInputData(evt)}
              />{error}
            </Form.Group>
  );
};
